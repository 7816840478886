<template>
  <div id="app" v-loading="loading">
    <el-upload
        class="upload-demo"
        drag
        name="execl_file"
        :headers="config"
        :on-progress="progress"
        accept=".xlsx, .xls"
        :on-success="handleAvatarSuccess"
        action="https://card-system.kafa888.cn/api/app/taskexecl"
        multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传excel表单.</div>
    </el-upload>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="80%"
        :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="成功录入">
          <el-input v-model="form.success_num" disabled></el-input>
        </el-form-item>
        <el-form-item label="号码重复过滤">
          <el-input v-model="form.unique_num" disabled></el-input>
        </el-form-item>
        <el-form-item label="号码格式过滤">
          <el-input v-model="form.format_num" disabled></el-input>
        </el-form-item>
        <el-form-item label="号码黑名单过滤">
          <el-input v-model="form.black_num" disabled></el-input>
        </el-form-item>
        <el-form-item label="全局号码重复过滤">
          <el-input v-model="form.repeat_num" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
           <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      config: {
        APPTOKEN: this.$route.query.APPTOKEN,
      },
      loading: false,
      form: {
        all_num: 0,
        black_num: 0,
        fail_num: 0,
        format_num: 0,
        repeat_num: 0,
        success_num: 0,
        unique_num: 0,
      }
    }
  },
  methods: {
    progress(){
      this.loading=true
    },
    handleAvatarSuccess(res) {
      try {
        if (res.code == 200) {
          this.dialogVisible = true
          this.form=res.data
        } else {
          this.$alert(res.msg, '未知异常', {
            confirmButtonText: '确定',
            callback: () => {
              this.dialogVisible = false
            }
          });
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0 0;
  padding: 0 0;
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
